import axios from "axios";

export function logout() {
  //const [loading, sendRequest] = useAxios();
  //const formdata = new FormData();
  // console.log(api_token);
  let apitoken = localStorage.getItem("api_token");

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/new/logout`,
      {},
      {
        headers: {
          "api-token": apitoken,
        },
      }
    )
    .then((res) => {
      console.log(res);
      localStorage.clear();
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
      localStorage.clear();
      window.location.href = "/";
    });
}
