import React, { useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import UserExperior from "user-experior-web";
import CryptoJS from "crypto-js";

const encryptFlag = process.env.REACT_APP_ENCRYPT_FLAG == '1'
const AES_BLOCK_SIZE = 16;
const AES_ENCRYPTION_KEY_LENGTH = 32

let RSA_PUBLIC_KEY = null

export default function useAxios(props) {
  const [loading, setLoading] = useState(false);

  const sendRequest = async (config, action) => {
    try {
      setLoading(true);
      config.headers["api-token"] = localStorage.getItem("api_token");

      const result = await UseJWTToken(config);

      if (typeof action === "function") {
        action(result)
      }

      return result
    } catch (error) {
      console.error("Request error:", error);
      throw error
    } finally {
      setLoading(false);
    }
  };

  return [loading, sendRequest];
}

async function UseJWTToken(config) {
  let aesKey = null
  if (!RSA_PUBLIC_KEY) {
    await loadRSAPublicKey()
  }

  if (encryptFlag){
    aesKey = generateAESKey();
  }
  
  return await isTokenExpired(config, aesKey);
}

async function isTokenExpired(config, aesKey) {
  const token = localStorage.getItem("api_token");
  const refresh_token = localStorage.getItem("refresh_token");
  console.log("token->", token);
  console.log("refresh_token->", refresh_token);

  if (config.skipRefresh) {
    console.log("Token expiry check skipped.");
    return await encrypt_and_call_api(config, aesKey);
  }

  if (token) {
    var decoded = jwt_decode(token);
    console.log("decoded->", decoded);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      return await generate_token(config, aesKey);
    }
  }
  return await encrypt_and_call_api(config, aesKey);
}

async function generate_token(config, aesKey) {
  const formdata = new FormData();

  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      if(encryptFlag){
        const data = {
          refresh_token: Cookies.get("refresh_token"),
        };
        const encryptedData = encrypt_data_aes(JSON.stringify(data), aesKey);
        formdata.append("encrypted_data", encryptedData);

        const AESEncryptedKey = await encryptAESKeyWithRSA(aesKey);
        config.headers = config.headers || {};
        config.headers["Security-Token"] = AESEncryptedKey
      }
      else{
        formdata.append("refresh_token", Cookies.get("refresh_token"));
      }
      try {
        let headers = {}
        if (config.headers && config.headers["Security-Token"]) {
          headers["Security-Token"] = config.headers["Security-Token"];
        }
        
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + "/get/access/token",
          {
            method: "POST",
            body: formdata,
            headers: Object.keys(headers).length > 0 ? headers : undefined
          }
        );

        if (response.ok) {
          const data3 = await response.json();
          localStorage.setItem("api_token", data3.access_token);
          config.headers["api-token"] = data3.access_token;
          return await encrypt_and_call_api(config, aesKey);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  } else{
    Cookies.remove("api_token");
    localStorage.clear();
    window.location.href = "/";
  }
}

async function encrypt_and_call_api(config, aesKey) {
  if(encryptFlag){
    config.url = encrypt_get_params(config.url, aesKey);
    if(config.params) config.params = encrypt_get_params(config.params, aesKey)
    let contentType = config.headers ? config.headers["Content-Type"] : undefined;
    
    if (!contentType && config.data) {
      contentType = get_content_type(config.data);
    }
  
    if (config.data) {
      const encryptedBody = await encrypt_post_data(config.data, contentType, aesKey);
  
      if(contentType === 'application/json'){
        const data = {
          encrypted_data: encryptedBody
        }
        config.data = JSON.stringify(data)
      }
      else{
        const formData = new FormData();
        formData.append("encrypted_data", encryptedBody);
        config.data = formData
      }
  
      if(config.headers) {
        config.headers["Content-Type"] = config.headers["Content-Type"] || contentType
        if(contentType === 'multipart/form-data') delete config.headers["Content-Type"]
      }
    }

    const AESEncryptedKey = await encryptAESKeyWithRSA(aesKey);
    config.headers = config.headers || {};
    config.headers["Security-Token"] = AESEncryptedKey
  }

  try {
    const response = await axios(config);
    if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
      const ue = new UserExperior();
      let urlName = config.url.split('/');
      urlName = urlName[urlName.length - 1]
          ue.startRecording(Cookies.get('parameter_value'), {
            sessionReplay: { 
                maskAllInputs: true,
                maskInputOptions: {
                    password: true,
                    email: true,
                    tel: true,
                    color: false,
                    date: false,
                    'datetime-local': false,
                    month: false,
                    number: false,
                    range: false,
                    search: false,
                    text: true,
                    time: false,
                    url: false,
                    week: false,
                    textarea: false,
                    select: false,
                }
            }
          });
          ue.setUserIdentifier(`${localStorage.getItem("in_username")}_${process.env.REACT_APP_ENVIRONMENT}`);
          // ue.setUserIdentifier(localStorage.getItem("in_userid"));
          ue.logEvent(urlName, {
            'headers': JSON.stringify(config.headers),
            'method': config.method,
            'url': config.url
          });
          ue.logEvent('response', {
            'headers': JSON.stringify(config.headers),
            'status': config.status
          });
    }
    return response.data;
  } catch (error) {
    console.error("API Call Error:", error);
    throw error;
  }
}

const get_content_type = (data) => {
  if (data instanceof FormData) {
    return 'multipart/form-data';
  } else if (typeof data === 'object') {
    return 'application/json'
  } else if (typeof data === 'string') {
    if(isvalidjson(data)) return 'application/json'
    else if(isUrlEncoded(data)) return 'application/x-www-form-urlencoded';
    else return 'text/plain'
  }
  return undefined
};

const isUrlEncoded = (data) => {
  try {
    return decodeURIComponent(data) !== data;
  } catch (e) {
    return false;
  }
};

const isvalidjson = (data) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
}

const encrypt_get_params = (urlOrParams, aesKey) => {
  if (typeof urlOrParams === 'string') {
    const urlObj = new URL(urlOrParams);
    const params = new URLSearchParams(urlObj.search);
    const encryptedParams = encrypt_params(params, aesKey);
    urlObj.search = encryptedParams.toString();
    return urlObj.toString();
  } else {
    const encryptedParams = encrypt_params(urlOrParams, aesKey);
    return encryptedParams
  }
};

const encrypt_params = (params, aesKey) => {
  if (params instanceof URLSearchParams) {
    params.forEach((value, key) => {
      const encryptedValue = encrypt_data_aes(value, aesKey);
      params.set(key, encryptedValue);
    });
    return params;
  } else if (typeof params === 'object' && params !== null) {
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      const encryptedValue = encrypt_data_aes(value, aesKey);
      urlParams.append(key, encryptedValue);
    });
    return urlParams;
  } else {
    throw new Error('Invalid parameters. Must be URLSearchParams or a JSON object.');
  }
};

const encrypt_post_data = async (bodySrc, contentType, aesKey) => {
  if (contentType && contentType.includes("application/json")) {
    try {
      const parsedBody = typeof bodySrc === "string" ? JSON.parse(bodySrc) : bodySrc;
      return encrypt_data_aes(JSON.stringify(parsedBody), aesKey);
    } catch (error) {
      console.error("Failed to parse JSON body:", error);
      throw new Error("Invalid JSON body");
    }
  } else if (contentType && contentType.includes("multipart/form-data")) {
    const jsonObject = {};
    const fileConversionPromises = []
    const filesConverted = []
    for (const [key, value] of bodySrc.entries()) {
      if (value instanceof File) {
        fileConversionPromises.push(
          file_to_base64(value).then(base64Data => {
            const mime_type = value.type || 'application/octet-stream';
            filesConverted.push({
              content: base64Data,
              filename: value.name,
              filetype: mime_type
            })
          })
        );
      } else {
        jsonObject[key] = value;
      }
    }
    await Promise.all(fileConversionPromises);
    if(filesConverted.length > 0){
      jsonObject["file"] = filesConverted
    }

    return encrypt_data_aes(JSON.stringify(jsonObject), aesKey);
  } else if (contentType && contentType.includes("application/x-www-form-urlencoded")) {
    const params = new URLSearchParams(bodySrc);
    const jsonConvertedBody = {};
    params.forEach((value, key) => {
      jsonConvertedBody[key] = value;
    });
    return encrypt_data_aes(JSON.stringify(jsonConvertedBody), aesKey);
  } else {
    return encrypt_data_aes(bodySrc, aesKey);
  }
};

function encrypt_data_aes(data, aesKey) {
  const keyBytes = CryptoJS.lib.WordArray.create(aesKey);
  const iv = CryptoJS.lib.WordArray.random(AES_BLOCK_SIZE);

  const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const combinedData = iv.concat(encrypted.ciphertext);
  const hexEncoded = CryptoJS.enc.Hex.stringify(combinedData);

  return hexEncoded;
}

const file_to_base64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const loadRSAPublicKey = async () => {
  try {
    const response = await fetch('/keys/rsa_public_key.pem');
    if (!response.ok) {
      throw new Error('Failed to load RSA public key');
    }
    const rawKey = await response.text();
    RSA_PUBLIC_KEY = rawKey.replace(/-----.*-----|\s/g, '');
    console.log("RSA Public Key Loaded", RSA_PUBLIC_KEY);
  } catch (error) {
    console.error("Error loading RSA public key:", error);
  }
};

const importRSAPublicKey = async (pemKey) => {
  const publicKeyBytes = base64ToUint8Array(pemKey);
  return window.crypto.subtle.importKey(
    'spki',
    publicKeyBytes,
    { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
    true,
    ['encrypt']
  );
};

const encryptAESKeyWithRSA = async (aesKey) => {
  try {
    if (!RSA_PUBLIC_KEY) {
      await loadRSAPublicKey();
    }

    const publicKey = await importRSAPublicKey(RSA_PUBLIC_KEY);

    const encryptedKey = await window.crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      publicKey,
      aesKey
    );

    // Convert encrypted key to Base64
    const encryptedKeyBase64 = arrayBufferToHex(encryptedKey);
    return encryptedKeyBase64;
  } catch (error) {
    console.error('Error encrypting AES key:', error);
    throw error;
  }
};

const arrayBufferToHex = (buffer) => {
  const byteArray = new Uint8Array(buffer);
  return Array.from(byteArray).map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const generateAESKey = () => {
  return window.crypto.getRandomValues(new Uint8Array(AES_ENCRYPTION_KEY_LENGTH)); // 32 bytes = 256 bits
};